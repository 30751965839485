<!-- 寄送奖品 -->
<template>
  <div class="prize-receive-way item1">
    <!-- 表单区域 -->
    <div class="prize-receive-way__left">
      <vh-form label-width="104px">
        <vh-form-item
          v-for="(item, index) in inputList"
          :key="index"
          :label="item.field"
          :ref="`${item.field_key}`"
        >
          <VhallInput
            v-model="givePrizeForm[item.field_key]"
            type="text"
            show-word-limit
            :maxlength="awardLimitNum[item.rank > 3 ? 3 : item.rank - 1]"
            :placeholder="item.placeholder"
          ></VhallInput>
          <div class="inputop-bar" v-if="index > 0">
            <i
              class="iconfont-v3 saasicon-trash"
              @click="deleteGivePrize(index)"
              v-if="!Boolean(item.is_system)"
            ></i>
            <p class="inputop-switchbox">
              <template v-if="item.field_key === 'phone'">
                <vh-switch
                  v-model="item.is_check"
                  inactive-text="手机号校验"
                  :active-value="1"
                  :inactive-value="0"
                  @change="handlePhoneCheckChange"
                  style="margin-right: 15px"
                ></vh-switch>
              </template>
              <vh-switch v-model="item.is_required" inactive-text="必填"></vh-switch>
            </p>
          </div>
        </vh-form-item>
        <div class="btn-add" @click="addField" v-if="inputList.length < 6">
          <i class="vh-icon-plus"></i>
          添加字段
        </div>

        <vh-form-item label="强制领奖">
          <vh-switch
            v-model="compulsory_award"
            :active-value="1"
            :inactive-value="0"
            active-text="开启后，中奖用户在未完成领奖时将无法关闭中奖页面及提交领奖页"
          ></vh-switch>
        </vh-form-item>

        <vh-form-item label="隐私协议">
          <ComplianceChoice
            ref="complianceChoice"
            v-model="privacyData"
            @change="complianceChange"
          ></ComplianceChoice>
        </vh-form-item>

        <vh-form-item label-width="38px">
          <vh-button
            type="primary"
            size="medium"
            round
            class="btn-save"
            @click="handleSubmit"
            v-preventReClick
          >
            保存
          </vh-button>
        </vh-form-item>
      </vh-form>
    </div>
    <!-- 预览区域 -->
    <div class="prize-receive-way__right">
      <div class="prize-receive-preview">
        <div class="prize-receive-preview__popup">
          <div v-if="!compulsory_award" class="has-close"></div>

          <div class="prize-receive-preview__popup-inner">
            <div class="prize-receive-preview__formbox">
              <vh-form :model="givePrizeForm">
                <vh-form-item v-for="(item, index) in inputList" :key="index" class="form-item">
                  <span class="red" v-if="item.is_required == 1">*</span>
                  <vh-input
                    v-model="givePrizeForm[item.field_key]"
                    readonly
                    type="text"
                    :placeholder="item.placeholder"
                  ></vh-input>
                </vh-form-item>
              </vh-form>
            </div>
            <div class="prize-receive-preview__submitbtn">提交</div>
            <div class="privacy-preview" v-html="privacyHtm"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import ComplianceChoice from '@/components/privacy-compliance/ComplianceChoice.vue';
  import to from 'await-to-js';

  export default {
    name: 'WayItem1',
    components: {
      ComplianceChoice
    },
    inject: ['sharedDataFun'],
    data() {
      return {
        compulsory_award: 0, // 是否强制领奖
        awardLimitNum: [20, 20, 50, 100],
        givePrizeForm: {
          adressCheced: false,
          name: '',
          phone: '',
          address: '',
          user_define_100: '',
          user_define_101: '',
          user_define_102: '',
          placeholder: ''
        },
        index: 0,
        inputList: [
          {
            is_system: 1,
            field: '姓名',
            field_key: 'name',
            is_required: true,
            placeholder: '请输入姓名',
            customPlaceholder: '',
            rank: 1
          },
          {
            is_system: 1,
            field: '手机号',
            field_key: 'phone',
            is_required: true,
            is_check: 1, // 1：按照11位手机号检测  0：按照20位数字检测
            placeholder: '请输入手机号',
            rank: 2
          },
          {
            is_system: 1,
            field: '联系地址',
            field_key: 'address',
            is_required: false,
            placeholder: '请输入地址',
            rank: 3
          }
        ],
        // 隐私协议相关数据
        privacyData: {
          statement_status: 0, //默认用系统自带协议
          statement_content: '',
          statement_info: []
        },

        privacyHtm: ''
      };
    },
    computed: {
      sharedData() {
        return this.sharedDataFun();
      }
    },
    methods: {
      reInit(defa) {
        // console.log('defa:', defa);
        if (!defa) return;
        this.compulsory_award = defa.compulsory_award;
        this.privacyData = defa.privacyData;
        if (Array.isArray(defa?.data)) {
          for (const item of defa.data) {
            this.givePrizeForm[item.field_key] = item.placeholder;
            item.is_required = Boolean(item.is_required);
            // 补充is_check字段
            this.$set(item, 'is_check', Number(item.is_check));
          }
          this.inputList = defa.data;
        }
      },
      addField() {
        if (this.inputList.length == 6) {
          this.$vhMessage('最多只能添加三个自定义选项');
          return false;
        }
        this.index++;
        this.inputList.push({
          is_system: 0,
          field: `自定义${this.index}`,
          field_key: 'user_define_' + (this.inputList.length + 97),
          is_required: false,
          placeholder: '请输入备注信息',
          maxlength: 100,
          rank: this.inputList.length + 97
        });
      },
      deleteGivePrize(index) {
        this.inputList.splice(index, 1);
      },
      // 【寄送奖品】 设置保存
      async handleSubmit() {
        // 顺序: 先保存隐私协议，再保存表单数据
        // 保存隐私协议
        const isOk = await this.savePrivacyAgreement();
        if (!isOk) return;

        // 再保存表单数据
        this.inputList.forEach(ele => {
          ele.placeholder = this.givePrizeForm[ele.field_key];
        });
        const payload = {
          compulsory_award: this.compulsory_award, // 是否强制领奖
          data: JSON.stringify(this.inputList)
        };
        this.$emit('save', payload);
      },
      // 保存隐私协议
      async savePrivacyAgreement() {
        if (this.privacyData.statement_status === 1) {
          // 自定义协议时的校验
          if (
            !this.privacyData.statement_content ||
            !Array.isArray(this.privacyData.statement_info) ||
            this.privacyData.statement_info.length === 0
          ) {
            this.$vhMessage.error('请完善自定义隐私协议数据');
            return;
          }
          const hasEmpty = this.privacyData.statement_info.some(item => {
            return !item.title || !item.link;
          });
          if (hasEmpty) {
            this.$vhMessage.error('请完善自定义隐私协议数据');
            return;
          }
        }
        const params = {
          webinar_id: this.sharedData.webinarId,
          module: 3, // 互动有礼模块
          source_id: this.sharedData.interactRewardId,
          ...this.privacyData
        };
        if (Array.isArray(params.statement_info)) {
          // 保存提交的时候用字符串
          params.statement_info = JSON.stringify(params.statement_info);
        }
        const [err, res] = await to(this.$fetch('savePrivacyAgreement', params));
        if (err || !res || res.code != 200) {
          this.$message.error(err.msg || res?.msg || '请求出现异常');
          return false;
        }
        return true;
      },
      // 隐私协议变更
      complianceChange({ privacyHtm }) {
        this.privacyHtm = privacyHtm;
      },
      async handlePhoneCheckChange(val) {
        if (!val) {
          const confirm = await this.$vhConfirm(
            '关闭后，只需要输入数字即可，将无法保证手机号的准确性，是否关闭？',
            '提示',
            {
              roundButton: true,
              confirmButtonText: '仍要关闭',
              cancelButtonText: '暂不关闭',
              customClass: 'zdy-message-box',
              lockScroll: false,
              closeOnClickModal: false,
              closeOnPressEscape: false,
              cancelButtonClass: 'zdy-confirm-cancel'
            }
          ).catch(() => false);
          if (!confirm) {
            // 暂不关闭, 把开关状态还原回去
            this.givePrizeForm;
            for (const item of this.inputList) {
              if (item.field_key === 'phone') {
                item.is_check = 1;
                break;
              }
            }
          }
        }
      }
    },
    mounted() {
      this.privacyHtm = this.$refs.complianceChoice.getCurrentPreviewHtm();
    }
  };
</script>
<style lang="less">
  .prize-receive-way.item1 {
    .inputop-bar {
      height: 20px;
      display: flex;
      justify-content: flex-end;
      i {
        font-size: 16px;
        vertical-align: top;
        line-height: 40px;
        cursor: pointer;
      }
    }

    .inputop-switchbox {
      display: inline-block;
      padding-left: 5px;
    }
    .vh-switch__label > span {
      line-height: 1.4;
    }
    .vh-switch__label.is-active {
      color: #8c8c8c;
    }
    .btn-add {
      margin-left: 100px;
      margin-bottom: 10px;
      font-size: 14px;
      color: #3562fa;
      cursor: pointer;
      i {
        color: #3562fa;
        // border: 1px dashed #3562FA;
      }
      .vh-icon-plus:before {
        font-weight: bolder;
      }
    }

    .prize-receive-preview__popup {
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      bottom: 0;
      width: 276px;
      height: auto;
      margin-left: 0;
      background-image: url('../../../../../common/images/gif/accept-review2.png');
      background-size: 100%;
      background-repeat: no-repeat;
      border-radius: 0 0 24px 24px;

      .has-close {
        position: absolute;
        top: 12px;
        right: 14px;
        &:after {
          content: '\00D7';
          font-size: 18px;
          font-weight: normal;
          color: #ffce70;
        }
      }

      &-inner {
        padding-top: 45px;
      }

      .prize-receive-preview__formbox {
        text-align: center;
        margin: 10px auto 16px;
        height: 160px;
        overflow-y: auto;
        padding: 0 16px;

        p {
          margin-top: 24px;
        }
        .red {
          position: absolute;
          left: 0px;
          top: 2px;
          display: inline-block;
          color: #fe6a6a;
          margin-right: 10px;
        }
      }
      .form-item {
        // padding-left: 15px;
        background: #fff;
        border-radius: 4px;
        margin-bottom: 9px;
        /deep/ input {
          border: 0;
          color: #bfbfbf;
        }
      }

      .prize-receive-preview__submitbtn {
        width: 128px;
        margin: auto;
        text-align: center;
        background: #ffd1c9;
        color: #fff;
        font-size: 14px;
        height: 32px;
        line-height: 32px;
        border-radius: 20px;
      }

      .privacy-preview {
        display: block;
        position: relative;
        z-index: 2;
        font-size: 12px;
        padding: 10px 15px;
        line-height: 1.5;
        background: linear-gradient(37.94deg, #fffbe8 3.86%, #fbf0e6 84.36%);
        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 24px;

        a {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 1.5;
          word-break: break-word;
          text-decoration: none;
          font-weight: 400;
          color: #3562fa;
          transform: matrix(0.99, 0, -0.14, 1, 0, 0);

          &:hover {
            color: #3562fa;
          }
        }
      }
    }
  }
</style>
