<!-- 适用于弹出选择对话框, 选择内容后tag展示 -->
<template>
  <div class="choose-things">
    <ChoiceButton
      v-show="showChooseButton"
      v-bind="$attrs"
      :label="label"
      v-on="$listeners"
    ></ChoiceButton>

    <div class="choose-things__result" v-if="chosenList.length > 0">
      <template v-if="mode === 'single'">
        <span class="choose-tag" @click="handleRefresh(chosenList[0])">
          <span class="tag-title">{{ chosenList[0][tagTitle] }}</span>
          <i class="vh-icon-refresh-left"></i>
        </span>
      </template>
      <template v-else>
        <span :key="tag[tagKey]" v-for="tag in chosenList" class="choose-tag">
          <span class="tag-title">{{ tag[tagTitle] }}</span>
          <i class="vh-icon-close" @click="handleRemove(tag)"></i>
        </span>
      </template>
    </div>
  </div>
</template>
<script>
  import ChoiceButton from '@/components/ChoiceButton/index.vue';

  export default {
    name: 'ChooseThings',
    inheritAttrs: false,
    components: {
      ChoiceButton
    },
    props: {
      mode: {
        type: String,
        default: 'single', // single：单选，multiple：多选
        required: true,
        validator: function (value) {
          return ['single', 'multiple'].includes(value);
        }
      },
      label: {
        type: String,
        required: true
      },
      // 选中的结果
      chosenList: {
        type: Array,
        default: []
      },
      tagKey: {
        type: String,
        default: 'id'
      },
      tagTitle: {
        type: String,
        default: 'title'
      }
    },
    computed: {
      showChooseButton() {
        return !(this.mode === 'single' && this.chosenList.length > 0);
      }
    },
    data() {
      return {};
    },
    methods: {
      handleRemove(tag) {
        const idx = this.chosenList.findIndex(item => item[this.tagKey] === tag[this.tagKey]);
        if (idx > -1) {
          this.chosenList.splice(idx, 1);
          this.$emit('update:chosenList', this.chosenList);
        }
      },
      handleRefresh(tag) {
        this.$emit('refreshOne', tag);
      }
    }
  };
</script>
<style lang="less">
  .choose-things {
    &__result {
      .choose-tag {
        background: #f0f0f0;
        border-radius: 50px;
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        color: #262626;
        vertical-align: middle;
        padding: 6px 12px;
        line-height: 20px;
        font-size: 14px;
        box-sizing: border-box;
        white-space: nowrap;

        .tag-title {
          display: inline-block;
          max-width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 14px;
        }

        i {
          color: #595959;
          border-radius: 50%;
          text-align: center;
          position: relative;
          cursor: pointer;
          font-size: 12px;
          height: 16px;
          width: 16px;
          line-height: 16px;
          vertical-align: middle;
          top: 1px;
          right: 0;
          margin-left: 4px;
        }

        &:hover {
          color: #fb2626;
          i {
            color: #fb2626;
          }
        }
      }
    }
  }
</style>
