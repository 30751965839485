<template>
  <div class="vhc-container vhc-container--InteractReward">
    <!-- 标题栏 -->
    <pageTitle :pageTitle="pageTitle"></pageTitle>

    <!-- 内容区 -->
    <div class="vhc-container__bd">
      <div class="vhc-main">
        <vh-tabs v-model="activeTab" @tab-click="handleChangeTab">
          <vh-tab-pane label="条件与礼品设置" name="1">
            <PrizeConditionSet ref="prizeConditionSet"></PrizeConditionSet>
          </vh-tab-pane>
          <vh-tab-pane label="领奖页设置" name="2" :disabled="!this.interactRewardId">
            <PrizeReceiveSet ref="prizeReceiveSet"></PrizeReceiveSet>
          </vh-tab-pane>
        </vh-tabs>
      </div>
    </div>

    <begin-play :webinarId="webinarId"></begin-play>
  </div>
</template>
<script>
  import PageTitle from '@/components/PageTitle';
  import PrizeConditionSet from './PrizeConditionSet.vue';
  import PrizeReceiveSet from './PrizeReceiveSet.vue';
  import beginPlay from '@/components/beginBtn';

  export default {
    name: 'InteractiveRewardSetting',
    components: {
      PageTitle,
      PrizeConditionSet,
      PrizeReceiveSet,
      beginPlay
    },
    provide() {
      return {
        updateSharedDataFun: this.updateSharedData,
        sharedDataFun: () => {
          return {
            interactRewardId: this.interactRewardId,
            webinarId: this.webinarId,
            roomId: this.roomId
          };
        }
      };
    },
    data() {
      return {
        roomId: '',
        webinarId: '', //活动id
        interactRewardId: '', // 互动有礼id，编辑的时候才有值
        copyId: '', // 复制的互动有礼id, 正常业务与interactRewardId不能同时都有值，否则优先使用copyId
        pageTitle: '',
        activeTab: '1'
      };
    },
    methods: {
      handleChangeTab() {
        this.$router.replace({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            tab: this.activeTab
          }
        });
        if (this.activeTab === '1') {
          this.$refs.prizeConditionSet.fetchData();
        } else if (this.activeTab === '2') {
          this.$refs.prizeReceiveSet.fetchData();
        }
      },
      updateSharedData(interactRewardId) {
        this.webinarId = this.$route.params.str || '';
        if (!interactRewardId) {
          this.interactRewardId = this.$route.query.irId || '';
          this.roomId = this.$route.query.roomId || '';
        } else {
          this.interactRewardId = interactRewardId;
        }
        this.pageTitle = this.copyId ? '复制' : this.interactRewardId ? '编辑' : '创建';
      }
    },
    created() {
      this.updateSharedData();
      if (this.interactRewardId) {
        this.activeTab = this.$route.query.tab || '1';
      }
    }
  };
</script>
<style lang="less">
  .vhc-container--InteractReward {
    .vh-tabs__content {
      min-height: 700px;
    }
  }
</style>
