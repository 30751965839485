<!-- 条件与礼品设置 -->
<template>
  <div class="prize-condition-set">
    <vh-form
      :model="form"
      :rules="rules"
      ref="prizeForm"
      label-width="80px"
      onsubmit="return false;"
    >
      <vh-form-item label="标题" align="left" prop="title" label-width="112px" required>
        <VhallInput
          v-model="form.title"
          autocomplete="off"
          :maxlength="100"
          placeholder="请输入标题"
          show-word-limit
        ></VhallInput>
      </vh-form-item>

      <vh-form-item label="任务条件" label-width="112px" required>
        <p class="text-mute">
          直播中完成「填写指定问卷」任务后，观众立即获得礼品；观看回放时完成任务不赠送礼品
        </p>
        <ChooseThings
          mode="single"
          label="选择问卷"
          :hasPermission="hasQuestionnaireAuth"
          :chosenList.sync="questionnaireList"
          tag-key="question_id"
          @click="handleChooseQuestionnaire"
          @refreshOne="handleChooseQuestionnaire"
        ></ChooseThings>
        <vh-alert
          v-if="status > -1 && !hasQuestionnaireAuth && isSubmit"
          title="当前无此权限"
          type="warning"
          show-icon
          :closable="false"
          class="alert-noauth"
        ></vh-alert>
      </vh-form-item>

      <vh-form-item label="选择礼品" label-width="112px" required class="choost-prize-item">
        <p class="text-mute">只能添加1个礼品</p>
        <ChoiceButton
          label="选择礼品"
          :hasPermission="hasPrizeAuth"
          v-show="prizeList.length === 0"
          @click="handleChooseReward"
        ></ChoiceButton>
        <vh-table
          v-if="prizeList.length > 0"
          :data="prizeList"
          max-height="504px"
          @cell-mouse-enter="handleCellMouseEnter"
          @cell-mouse-leave="handleCellMouseLeave"
        >
          <vh-table-column prop="img_path" label="礼品图片" width="120">
            <template slot-scope="scope">
              <div class="image-preview">
                <vh-image
                  style="height: 40px"
                  :src="scope.row.img_path"
                  :preview-src-list="[scope.row.img_path]"
                ></vh-image>
              </div>
            </template>
          </vh-table-column>
          <vh-table-column prop="prize_name" label="礼品标题" width="120">
            <template slot-scope="scope">
              <vh-tooltip
                :disabled="!isTextOverflow"
                effect="dark"
                placement="top-start"
                :content="scope.row.prize_name"
              >
                <div class="custom-tooltip-content">{{ scope.row.prize_name }}</div>
              </vh-tooltip>
            </template>
          </vh-table-column>
          <vh-table-column prop="prize_desc" label="礼品描述">
            <template slot-scope="scope">
              <vh-tooltip
                :disabled="!isTextOverflow"
                effect="dark"
                placement="top-start"
                :content="scope.row.prize_desc"
              >
                <div class="custom-tooltip-content">{{ scope.row.prize_desc }}</div>
              </vh-tooltip>
            </template>
          </vh-table-column>
          <vh-table-column prop="create_time" label="创建时间" width="140">
            <template slot-scope="scope">
              <span>{{ scope.row.create_time | dateFormat('YYYY-MM-DD HH:mm') }}</span>
            </template>
          </vh-table-column>
          <vh-table-column label="操作" width="80" fixed="right">
            <template slot-scope="scope">
              <vh-button
                :disabled="!hasPrizeAuth"
                type="text"
                size="tiny"
                @click="handleDelete(scope.row)"
              >
                删除
              </vh-button>
            </template>
          </vh-table-column>
        </vh-table>
        <vh-alert
          v-if="status > -1 && !hasPrizeAuth && isSubmit"
          title="当前无此权限"
          type="warning"
          show-icon
          :closable="false"
          class="alert-noauth"
        ></vh-alert>
      </vh-form-item>

      <vh-form-item label="展示效果" label-width="112px" required>
        <p class="text-mute">设置中奖动效在手机观看端展示位置</p>
        <vh-radio-group v-model="form.displayMode">
          <vh-radio :label="0" default>全屏展示</vh-radio>
          <vh-radio :label="1">半屏展示</vh-radio>
        </vh-radio-group>
      </vh-form-item>

      <vh-form-item label-width="35px">
        <vh-button
          type="primary"
          size="medium"
          round
          class="btn-save"
          :loading="loading"
          :disabled="status < 1"
          v-preventReClick
          @click="handleSubmit"
        >
          保存
        </vh-button>
      </vh-form-item>
    </vh-form>

    <!-- 选择问卷对话框 -->
    <check-question
      ref="checkQuestionDom"
      :bizType="0"
      :maxCheckedNum="1"
      :selected="questionIds"
      :includeIds="oriQuestionIds"
      @save="saveQuestionInfo"
    ></check-question>

    <!-- 选择礼品对话框 -->
    <ChooseReward
      ref="checkPrize"
      :source="0"
      :roomId="sharedData.roomId"
      :webinarId="sharedData.webinarId"
      :max-count="1"
      @before-add="beforePrizeAdd"
      @completeSelection="completePrizeSelection"
    ></ChooseReward>

    <!-- 创建/编辑礼品对话框 -->
    <AddOrEditReward
      ref="addReward"
      :roomId="sharedData.roomId"
      :webinarId="sharedData.webinarId"
      @completeAdd="completeAdd"
    ></AddOrEditReward>
  </div>
</template>
<script>
  import tableCellTooltip from '@/components/TableList/mixins/tableCellTooltip';
  import ChooseThings from '@/components/ChooseThings/index.vue';
  import ChoiceButton from '@/components/ChoiceButton/index.vue';
  import CheckQuestion from '@/components/Question/checkQuestion';
  import ChooseReward from '../dialog/ChooseReward.vue';
  import AddOrEditReward from '../dialog/AddOrEditReward.vue';
  import to from 'await-to-js';
  import { sessionOrLocal } from '@/utils/utils';

  export default {
    name: 'PrizeConditionSet',
    mixins: [tableCellTooltip],
    components: {
      ChooseThings,
      ChoiceButton,
      CheckQuestion,
      ChooseReward,
      AddOrEditReward
    },
    inject: ['sharedDataFun', 'updateSharedDataFun'],
    data() {
      return {
        copyId: '',
        userId: '',
        loading: false,
        status: -1, // -1初始，0获取数据有错误,1获取数据成功
        form: {
          title: '',
          displayMode: 0 // 展示效果，0-全屏（默认） 1-半屏
        },
        rules: {
          title: [{ required: true, message: '请输入标题', trigger: ['blur', 'change'] }]
        },

        hasInteractRewardAuth: false, //是否互动有礼权限
        hasQuestionnaireAuth: false, //是否问卷有权限
        hasPrizeAuth: false, //是否抽奖有权限

        oriQuestionIds: [], //最原始的已绑定的问卷id列表
        questionnaireList: [], // 选择的问卷列表
        prizeList: [], // 选择的礼品列表
        isSubmit: false // 是否提交了
      };
    },
    computed: {
      sharedData() {
        return this.sharedDataFun();
      },
      // 从对话框选中的的问卷的id集合,
      questionIds() {
        return this.questionnaireList.map(item => item.question_id);
      }
    },
    created() {
      this.userId = JSON.parse(sessionOrLocal.get('userId'));
    },
    methods: {
      // 选择问卷，弹窗问卷选择对话框
      handleChooseQuestionnaire() {
        if (!this.hasQuestionnaireAuth) return;
        this.$refs.checkQuestionDom.dialogVisible = true;
      },
      // 选择对话框中的问卷后回调
      saveQuestionInfo(questions) {
        if (questions.length < 1) return;
        this.questionnaireList = questions;
      },
      // 选择礼品，弹窗礼品选择对话框
      handleChooseReward() {
        this.$refs.checkPrize.dialogVisible = true;
      },
      beforePrizeAdd() {
        // 延迟打开，避免上个对话框关闭时闪烁
        setTimeout(() => {
          this.$refs.addReward.reset();
          this.$refs.addReward.dialogVisible = true;
        }, 300);
      },
      // 完成礼品选择
      completePrizeSelection(prizes) {
        this.prizeList = prizes || [];
        this.$refs.checkPrize.dialogVisible = false;
      },
      // 删除礼品
      async handleDelete(rows) {
        const [err, res] = await to(
          this.$vhConfirm('删除后，此礼品将无法使用，确认删除？', '提示', {
            roundButton: true,
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            customClass: 'zdy-message-box',
            lockScroll: false,

            closeOnClickModal: false,
            closeOnPressEscape: false,
            cancelButtonClass: 'zdy-confirm-cancel'
          })
        );
        if (err) return;
        const idx = this.prizeList.findIndex(item => item.prize_id === rows.prize_id);
        if (idx > -1) {
          this.prizeList.splice(idx, 1);
        }
      },
      // 添加奖品完成的回调
      async completeAdd(prize) {
        this.$vhMessage.success('创建成功');
        if (prize?.prize_id) {
          this.prizeList.push(prize);
        }
      },
      async fetchData() {
        this.status == -1;
        await Promise.all([this.fetchPermissions(), this.fetchBaseData()]);
        if (this.status == -1) {
          this.status = 1;
        }
      },
      // 获取权限
      async fetchPermissions() {
        const params = {
          webinar_id: this.sharedData.webinarId,
          webinar_user_id: this.userId,
          scene_id: 1
        };
        const [err, res] = await to(this.$fetch('planFunctionGet', params));
        if (err || res?.code != 200) {
          this.$message.error(err?.msg || res?.msg || '获取权限失败');
          this.status = 0;
          return;
        }
        const permissions = JSON.parse(res.data.permissions);
        // 是否有互动有礼权限
        this.hasInteractRewardAuth = permissions['ui.interact_reward'] == 1;
        // 是否有权限选择问卷
        this.hasQuestionnaireAuth = permissions['ui.hide_survey'] == 1;
        // 是否有权限选择礼品
        this.hasPrizeAuth = permissions['ui.hide_lottery'] == 1;

        if (!this.hasInteractRewardAuth) {
          this.$vhMessage.error('未开通互动有礼的权限');
          this.status = 0;
        }
      },
      // 获取互动ID获取基础数据（复制和编辑共用）
      async fetchBaseData() {
        if (!this.copyId && !this.sharedData.interactRewardId) return;
        const params = {
          webinar_id: this.sharedData.webinarId,
          interact_reward_id: this.copyId || this.sharedData.interactRewardId
        };
        const [err, res] = await to(this.$fetch('getInteractRewardBaseInfo', params));
        if (err || res?.code != 200) {
          this.$message.error(err?.msg || res?.msg || '获取数据失败');
          this.status = 0;
          return;
        }
        this.form.title = res.data.title;
        this.form.displayMode = res.data.display_of_results;
        if (this.copyId) {
          this.questionnaireList = [];
          this.oriQuestionIds = [];
        } else {
          const winningCondition = res.data.winning_condition;
          if (winningCondition?.survey_info?.question_id && winningCondition?.survey_info?.title) {
            this.questionnaireList = [winningCondition.survey_info];
            this.oriQuestionIds = this.questionnaireList.map(item => item.question_id);
          } else {
            // 问卷被删除的情况下，问卷标题为空，当做是没有选问卷处理
            this.questionnaireList = [];
            this.oriQuestionIds = [];
          }
        }
        if (Array.isArray(res.data.award_list) && res.data.award_list.length) {
          this.prizeList = res.data.award_list.map(item => {
            return {
              prize_id: item.award_id,
              prize_name: item.award_name,
              prize_desc: item.award_desc,
              img_path: item.award_img_url,
              create_time: item.created_at
            };
          });
        } else {
          this.prizeList = [];
        }
      },
      //【互动有礼】"条件与礼品设置" 保存
      async handleSubmit() {
        this.isSubmit = true;
        if (!this.hasInteractRewardAuth) {
          this.$vhMessage.error('您需要先开通『互动有礼』的权限');
          return;
        }
        if (!this.hasQuestionnaireAuth || !this.hasPrizeAuth) {
          const fea =
            (!this.hasQuestionnaireAuth ? '『问卷』' : '') +
            (!this.hasPrizeAuth ? '『在线抽奖』' : '');
          this.$vhMessage.error(`您需要先开通${fea}权限`);
          return;
        }
        const valid = await this.$refs['prizeForm'].validate().catch(() => false);
        if (!valid) return;
        if (this.questionnaireList.length === 0) {
          this.$vhMessage.error('请选择问卷');
          return;
        }
        if (this.prizeList.length === 0) {
          this.$vhMessage.error('请选择礼品');
          return;
        }
        // 组织参数;
        const winningCondition = JSON.stringify({
          type: 0,
          survey_info: { question_id: this.questionnaireList[0].question_id }
        });
        const params = {
          webinar_id: this.sharedData.webinarId,
          title: this.form.title, // 标题
          winning_condition: winningCondition, // 条件
          award_id: this.prizeList[0].prize_id, // 礼品id
          display_of_results: this.form.displayMode // 展示效果，0-全屏（默认） 1-半屏
        };
        if (this.copyId) {
          params.copied_id = this.copyId; // 走复制
        } else if (this.sharedData.interactRewardId) {
          params.interact_reward_id = this.sharedData.interactRewardId; //有互动有礼id走编辑，否则走创建
        }
        // 提交数据
        this.loading = true;
        const [err, res] = await to(this.$fetch('saveInteractRewardBaseInfo', params));
        this.loading = false;
        if (err || !res || res.code != 200) {
          this.$message.error(err.msg || res?.msg || '请求出现异常');
          return;
        }
        this.oriQuestionIds = this.questionnaireList.map(item => item.question_id);
        if (this.copyId || (!this.copyId && !this.sharedData.interactRewardId)) {
          // 复制或者新增完成，跳转到编辑模式
          const interactRewardId = res.data.interact_reward_id;
          this.copyId = '';
          this.$router.push({
            path: `/live/InteractiveRewardSetting/${this.sharedData.webinarId}`,
            query: {
              roomId: this.$route.query.roomId,
              irId: interactRewardId
            }
          });
          this.updateSharedDataFun(interactRewardId);
        }
        this.$vhMessage.success('保存成功');
      }
    },
    mounted() {
      this.copyId = this.$route.query.copy_id || '';
      this.fetchData();
    }
  };
</script>
<style lang="less">
  .prize-condition-set {
    max-width: 680px;
    margin-top: 12px;

    .choost-prize-item {
      .vh-form-item__content {
        line-height: 1;

        .text-mute {
          height: 40px;
          line-height: 40px;
          vertical-align: middle;
        }
      }
    }
    .alert-noauth {
      padding: 0 0 0 8px;
      margin-top: 5px;
      width: 300px;
    }
    .btn-save {
      width: 96px;
      height: 40px;
      margin-top: 18px;
    }
  }

  .text-mute {
    color: #8c8c8c;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
</style>
