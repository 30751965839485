<!-- 领奖页设置 -->
<template>
  <div class="prize-receive-set">
    <vh-form label-width="104px">
      <vh-form-item label="领奖方式">
        <vh-radio-group v-model="receiveWay">
          <vh-radio :label="1">寄送奖品</vh-radio>
          <vh-radio :label="2">私信兑奖</vh-radio>
          <vh-radio :label="3">无需领奖</vh-radio>
        </vh-radio-group>
      </vh-form-item>

      <!-- 寄送奖品 -->
      <WayItem1 ref="wayItem1" v-show="receiveWay === 1" @save="saveData" />

      <!-- 私信兑奖 -->
      <WayItem2 ref="wayItem2" v-show="receiveWay === 2" @save="saveData" />

      <!-- 无需领奖 -->
      <WayItem3 ref="wayItem3" v-show="receiveWay === 3" @save="saveData" />
    </vh-form>
  </div>
</template>
<script>
  import to from 'await-to-js';
  import WayItem1 from './ways/WayItem1.vue';
  import WayItem2 from './ways/WayItem2.vue';
  import WayItem3 from './ways/WayItem3.vue';

  export default {
    name: 'PrizeReceiveSet',
    components: {
      WayItem1,
      WayItem2,
      WayItem3
    },
    inject: ['sharedDataFun', 'updateSharedDataFun'],
    data() {
      return {
        // 领奖方式
        receiveWay: 1
      };
    },
    computed: {
      sharedData() {
        return this.sharedDataFun();
      }
    },
    methods: {
      // 获取领奖页设置信息
      async fetchData() {
        const params = {
          webinar_id: this.sharedData.webinarId,
          interact_reward_id: this.sharedData.interactRewardId
        };
        const [err, res] = await to(this.$fetch('getInteractRewardReceiveSetting', params));
        if (err || res?.code != 200) {
          this.$message.error(err?.msg || res?.msg || '获取数据失败');
          return;
        }
        console.log('getInteractRewardReceiveSetting res:', res);
        this.receiveWay = res.data.receive_award_way;
        this.$nextTick(() => {
          if (this.receiveWay === 1) {
            // 重设【寄送奖品】初始数据
            this.$refs.wayItem1.reInit({
              compulsory_award: res.data.compulsory_award,
              data: JSON.parse(res.data.json_data),
              privacyData: {
                statement_status: res.data.statement_status || 0,
                statement_content: res.data.statement_content || '',
                statement_info: res.data.statement_info || []
              }
            });
          } else if (this.receiveWay === 2) {
            // 重设【私信兑奖】初始数据
            this.$refs.wayItem2.reInit({
              data: JSON.parse(res.data.json_data)
            });
          }
        });
      },

      // 保存领奖页设置信息
      async saveData(payload) {
        // http://yapi.vhall.domain/project/101/interface/api/58919
        const params = {
          webinar_id: this.sharedData.webinarId,
          interact_reward_id: this.sharedData.interactRewardId,
          receive_award_way: this.receiveWay
        };
        Object.assign(params, payload);
        const [err, res] = await to(this.$fetch('saveInteractRewardReceiveSetting', params));
        if (err || !res || res.code != 200) {
          this.$message.error(err.msg || res?.msg || '请求出现异常');
          return;
        }
        this.$vhMessage.success('保存成功');
      }
    },
    mounted() {
      if (this.sharedData.interactRewardId) {
        this.fetchData();
      }
    }
  };
</script>
<style lang="less">
  // 领奖页设置通用样式
  .prize-receive-way {
    &__left {
      width: 480px;
      margin-right: 80px;
    }
    &__right {
      width: 370px;
      height: 631px;
      background-image: url('../../../../common/images/gif/prize.png');
      background-size: cover;
      margin-top: -25px;
      position: absolute;
      left: 600px;
      top: 0;

      .prize-receive-preview {
        position: absolute;
        top: 85px;
        left: 50px;
        width: 276px;
        height: 505px;
        background: rgba(0, 0, 0, 0.7);
        border-radius: 0 0 24px 24px;
      }
    }
    .btn-save {
      width: 96px;
      height: 40px;
      margin-top: 18px;
    }
  }
</style>
